///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Mini Posts */

	.mini-posts {
		article {
			border-top: solid 1px _palette(border);
			margin-top: _size(element-margin);
			padding-top: _size(element-margin);

			.image {
				display: block;
				margin: 0 0 (_size(element-margin) * 0.75) 0;

				img {
					display: block;
					width: 100%;
				}
			}

			&:first-child {
				border-top: 0;
				margin-top: 0;
				padding-top: 0;
			}
		}
	}
///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Wrapper */

	#wrapper {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'row-reverse');
		min-height: 100vh;
	}
///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

ol {
  list-style: decimal;
  margin: 0 0 _size(element-margin) 0;
  padding-left: 1.25em;

  li {
    padding-left: 0.25em;
  }
}

ul {
  list-style: disc;
  margin: 0 0 _size(element-margin) 0;
  padding-left: 1em;

  li {
    padding-left: 0.5em;
  }

  &.alt {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      border-top: solid 1px _palette(border);
      padding: 0.5em 0;

      &:first-child {
        border-top: 0;
        // padding-top: 0;
      }
      a {
        border-bottom: 0px;
        color: _palette(fg);
      }
    }
  }
}

dl {
  margin: 0 0 _size(element-margin) 0;

  dt {
    display: block;
    font-weight: _font(weight-bold);
    margin: 0 0 (_size(element-margin) * 0.5) 0;
  }

  dd {
    margin-left: _size(element-margin);
  }
}

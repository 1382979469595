///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Table */
// .table {
//   margin: 1em;
//   display: flex;
//   flex-direction: column;

//   .header,
//   .row {
//     display: flex;

//     div {
//       flex: 1;
//       padding: 0.2rem 0.4em;
//       border: 1px solid rgb(238, 238, 238);
//     }
//   }

//   .header {
//     background-color: rgb(238, 238, 238);
//     div {
//       cursor: pointer;
//     }
//   }
// }

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 _size(element-margin) 0;
  width: 100%;

  tbody {
    tr {
      border: solid 1px _palette(border);
      border-left: 0;
      border-right: 0;

      &:nth-child(2n + 1) {
        background-color: _palette(border-bg);
      }
    }
  }

  td {
    padding: 0.75em 0.75em;
    max-width: 5rem;
    overflow-y: scroll;
    overflow-x: scroll;
  }

  th {
    color: _palette(fg-bold);
    font-size: 0.9em;
    font-weight: _font(weight-bold);
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left;
  }

  thead {
    border-bottom: solid 2px _palette(border);
  }

  tfoot {
    border-top: solid 2px _palette(border);
  }

  &.alt {
    border-collapse: separate;

    tbody {
      tr {
        td {
          border: solid 1px _palette(border);
          border-left-width: 0;
          border-top-width: 0;

          &:first-child {
            border-left-width: 1px;
          }
        }

        &:first-child {
          td {
            border-top-width: 1px;
          }
        }
      }
    }

    thead {
      border-bottom: 0;
    }

    tfoot {
      border-top: 0;
    }
  }
}

///
/// Editorial by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Sidebar */

#search {
  form {
    @include icon;
    position: relative;

    &:before {
      @include vendor("transform", "scaleX(-1)");
      color: _palette(fg);
      content: "\f002";
      cursor: default;
      display: block;
      font-size: 1.5em;
      height: _size(element-height) / 1.375;
      line-height: _size(element-height) / 1.375;
      opacity: 0.325;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: _size(element-height) / 1.375;
    }

    input[type="text"] {
      padding-right: _size(element-height);
    }
  }
}

.toggle {
  @include icon;
  @include vendor("transition", "left 0.5s ease");
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 0;
  display: block;
  height: 7.5em;
  left: _size(sidebar-width) - 3em;
  line-height: 5em;
  outline: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  text-indent: 7.5em;
  top: 0;
  width: 6em;
  z-index: _misc(z-index-base);

  &:before {
    content: "\f0c9";
    font-size: 2rem;
    height: inherit;
    left: 0;
    line-height: inherit;
    position: absolute;
    text-indent: 0;
    top: 0;
    width: inherit;
  }

  &.inactive {
    margin-left: (_size(sidebar-width) * -1 + 4em);
  }
  // @include breakpoint("<=large") {
  //   border: 2px solid red;
  // }

  @include breakpoint("small-to-large") {
    // border: 2px solid purple;
    left: _size(sidebar-width) - 5em;
  }

  @include breakpoint("<=small") {
    // border: 2px solid pink;
    left: _size(sidebar-width) - 5em;
  }
  @include breakpoint("<=xsmall") {
    // border: 2px solid blue;
    //left: _size(sidebar-width) - 10em;
  }
  @include breakpoint("<=xxsmall") {
    // border: 2px solid green;
  }

  //@include breakpoint("<=small") {
  //   display: none;
  // }
}

#sidebar {
  $pad: 2em / 0.9;

  @include vendor("flex-grow", "0");
  @include vendor("flex-shrink", "0");
  @include vendor(
    "transition",
    ("margin-left 0.5s ease", "box-shadow 0.5s ease")
  );
  background-color: _palette(bg-alt);
  font-size: 0.9em;
  position: relative;
  width: _size(sidebar-width);

  h2 {
    font-size: (1.25em / 0.9);
  }

  > .inner {
    @include padding($pad, $pad, (0, 0, $pad, 0));
    position: relative;
    width: _size(sidebar-width);

    > * {
      border-bottom: solid 2px _palette(border);
      margin: 0 0 (_size(element-margin) * 1.75) 0;
      padding: 0 0 (_size(element-margin) * 1.75) 0;

      > :last-child {
        margin-bottom: 0;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    > .alt {
      background-color: darken(_palette(bg-alt), 2);
      border-bottom: 0;
      margin: ($pad * -1) 0 ($pad * 2) ($pad * -1);
      padding: $pad;
      width: calc(100% + #{$pad * 2});
    }
  }

  .toggle {
    @include icon;
    @include vendor("transition", "left 0.5s ease");
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    border: 0;
    display: block;
    height: 7.5em;
    left: _size(sidebar-width);
    line-height: 7.5em;
    outline: 0;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-indent: 7.5em;
    top: 0;
    width: 6em;
    z-index: _misc(z-index-base);

    &:before {
      content: "\f0c9";
      font-size: 2rem;
      height: inherit;
      left: 0;
      line-height: inherit;
      position: absolute;
      text-indent: 0;
      top: 0;
      width: inherit;
    }
  }

  &.inactive {
    margin-left: (_size(sidebar-width) * -1);
  }

  @include breakpoint("<=xlarge") {
    $pad: 1.5em / 0.9;

    width: _size(sidebar-width-alt);

    > .inner {
      @include padding($pad, $pad, (0, 0, $pad, 0));
      width: _size(sidebar-width-alt);

      > .alt {
        margin: ($pad * -1) 0 ($pad * 2) ($pad * -1);
        padding: $pad;
        width: calc(100% + #{$pad * 2});
      }
    }

    .toggle {
      height: 6.25em;
      left: _size(sidebar-width-alt);
      line-height: 6.25em;
      text-indent: 5em;
      width: 5em;

      &:before {
        font-size: 1.5rem;
      }
    }

    &.inactive {
      margin-left: (_size(sidebar-width-alt) * -1);
    }
  }

  @include breakpoint("<=small") {
    box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.175);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    z-index: _misc(z-index-base);

    &.inactive {
      box-shadow: none;
    }

    > .inner {
      -webkit-overflow-scrolling: touch;
      height: 100%;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      top: 0;

      &:after {
        content: "";
        display: block;
        height: 4em;
        width: 100%;
      }
    }
  }
  //   .toggle {
  //     text-indent: 6em;
  //     width: 6em;

  //     &:before {
  //       font-size: 1.5rem;
  //       margin-left: (-0.875em / 2);
  //     }
  //   }

  //   body.is-preload & {
  //     display: none;
  //   }
  // }

  @include breakpoint("<=small") {
    .toggle {
      text-indent: 7.25em;
      width: 7.25em;

      &:before {
        color: _palette(fg);
        margin-left: (-0.125em / 2);
        margin-top: (-0.5em / 2);
        font-size: 1.1rem;
        z-index: 1;
      }

      &:after {
        background: transparentize(lighten(_palette(fg), 35), 0.25);
        border-radius: _size(border-radius);
        content: "";
        height: 3.5em;
        left: 1em;
        position: absolute;
        top: 1em;
        width: 5em;
      }
    }
  }
}
